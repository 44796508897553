import {
    FETCH_RANCHES,
    FETCH_CREATE_RANCH,
    FETCH_DELETE_RANCH,
    FETCH_UPDATE_RANCH,
    FETCH_UPDATE_RANCH_SETTINGS,
} from '../actionTypes';

export const fetchRanches = () => ({
    type: 'FETCH',
    params: {
        type: FETCH_RANCHES.default,
        url: '/ranches',
    },
});

export const fetchCreateRanch = ranch => ({
    type: 'FETCH',
    params: {
        type: FETCH_CREATE_RANCH.default,
        url: '/ranch',
        method: 'POST',
        body: {
            ranch,
        },
    },
});

export const fetchDeleteRanch = id => ({
    type: 'FETCH',
    params: {
        type: FETCH_DELETE_RANCH.default,
        url: `/ranches/${id}`,
        method: 'DELETE',
    },
});

export const fetchUpdateRanch = (id, ranch) => ({
    type: 'FETCH',
    params: {
        type: FETCH_UPDATE_RANCH.default,
        url: `/ranches/${id}`,
        method: 'PATCH',
        body: ranch,
    },
});

export const fetchUpdateRanchSettings = ({ id, body }) => ({
    type: 'FETCH',
    params: {
        type: FETCH_UPDATE_RANCH_SETTINGS.default,
        url: `/ranches/${id}/settings`,
        method: 'PATCH',
        body,
    },
});
