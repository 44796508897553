import { eventChannel } from 'redux-saga';
import { select, call, put, take, cancelled, cancel, fork } from 'redux-saga/effects';
import { auth } from '@beewise/react-utils';
import io from 'socket.io-client';
import dayjs from 'dayjs';
import { API_URL } from 'config';
import constants from 'appConstants';
import { FETCH_SIGNIN, SIGN_OUT, SOCKET_CONNECTION_FAILED } from 'components/views/SignIn/actionTypes';
import { SET_CURRENT_BHOME } from 'components/views/Dashboard/actionTypes';
import { SOCKET_CONNECTED, ON_UPDATE_BHOME_STATUS, ON_UPDATE_CONFIG } from '../actionTypes';

let connection = null;

const connect = async (currentBhomeId = null) => {
    const user = auth.getUser();

    if (!user) {
        return;
    }

    const queryParams = currentBhomeId
        ? `bhomeId=${currentBhomeId}&token=${auth.getSocketJwtToken()}`
        : `general=default&token=${auth.getSocketJwtToken()}`;
    const socket = io(`${API_URL}/technician`, {
        query: queryParams,
        forceNew: true,
        transports: ['websocket'],
    });

    return new Promise(resolve => {
        socket.on('connect', () => {
            resolve(socket);
        });
        socket.on('connect_error', error => {
            if (
                error?.message?.toLowerCase?.()?.includes?.('invalid signature') ||
                error?.message?.toLowerCase?.()?.includes?.('malformed')
            ) {
                resolve({ error: true });
            }
        });
    });
};

const createSocketChannel = socket =>
    eventChannel(emit => {
        const emitter = data => {
            emit(data);
        };

        socket.on('data', emitter);

        return () => {
            socket.off('data', emitter);
        };
    });

function* socketConnect() {
    let socket;
    let socketChannel;

    try {
        const currentBhomeId = yield select(state => state.dashboard.currentBhomeId);

        socket = yield call(connect, currentBhomeId);

        if (!socket) {
            return;
        }

        if (socket.error) {
            yield put({ type: SOCKET_CONNECTION_FAILED });
            return;
        }

        yield put({ type: SOCKET_CONNECTED });

        socketChannel = yield call(createSocketChannel, socket);

        while (true) {
            const wsActionObj = yield take(socketChannel);
            const wsAction = JSON.parse(wsActionObj);
            const { event, payload } = wsAction;

            if (event === constants.EVENT_NAMES.UPDATE_BHOME_STATUS) {
                yield put({
                    type: ON_UPDATE_BHOME_STATUS,
                    payload,
                    updatedBhome: wsAction.updatedBhome,
                });
            } else if (event === constants.EVENT_NAMES.UPDATE_CONFIG) {
                yield put({ type: ON_UPDATE_CONFIG, payload });
            } else if (event === constants.EVENT_NAMES.GET_CSV_REPORT) {
                if (!payload?.link) {
                    return;
                }
                const link = document.createElement('a');
                link.href = payload.link;
                const fileName = `Report_${dayjs().format('MM/DD/YYYY_HH:mm')}_(${Date.now()}).csv`;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
            }
        }
    } finally {
        if (yield cancelled()) {
            connection = null;

            if (socket) {
                socket.close();
            }

            if (socketChannel) {
                socketChannel.close();
            }
        }
    }
}

function* disconnect() {
    yield cancel(connection);
}

function* selectCurrentBhome() {
    yield cancel(connection);
    connection = yield fork(socketConnect);
}

const customTakeEvery = (pattern, saga, ...args) =>
    // eslint-disable-next-line func-names
    fork(function* () {
        while (true) {
            const action = yield take(pattern);
            connection = yield fork(saga, ...args.concat(action));
        }
    });

export default function* root() {
    connection = yield fork(socketConnect);
    yield customTakeEvery(FETCH_SIGNIN.success, socketConnect);
    yield customTakeEvery(SET_CURRENT_BHOME, selectCurrentBhome);
    yield customTakeEvery(SIGN_OUT, disconnect);
}
