import React, { useCallback, useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { loading, arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import Grid from 'components/reusables/Grid';
import { clearViewData } from 'actions';
import './Ranches.scss';
import CheckboxComponent from '@beewise/checkbox-field';
import { fetchRanches, fetchCreateRanch, fetchDeleteRanch, fetchUpdateRanchSettings } from './actions';
import { FETCH_RANCHES, FETCH_DELETE_RANCH, FETCH_CREATE_RANCH, FETCH_UPDATE_RANCH_SETTINGS } from './actionTypes';

const CheckBox = ({ onClick, data }) => (
    <CheckboxComponent checked={data?.showPotential} onClick={onClick} id={data?.id} />
);

CheckBox.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    onClick: PropTypes.func,
    data: PropTypes.shape(),
};

const Ranches = () => {
    const [, setGridApi] = useState(null);

    const dispatch = useDispatch();

    const ranches = useSelector(state => state.ranches.ranches, arrayOfObjectsShallowEqual);

    const isFetched = useSelector(state => state.ranches.isFetched);

    useEffect(() => {
        dispatch(fetchRanches());
        return () => dispatch(clearViewData());
    }, [dispatch]);

    const handleOnGridReady = useCallback(gridApi => {
        setGridApi(gridApi);
    }, []);

    const handleNewItemSave = useCallback(
        newItem => {
            const { id, ...rest } = newItem;

            dispatch(
                fetchCreateRanch({
                    ...rest,
                })
            );
        },
        [dispatch]
    );

    const handleItemDelete = useCallback(data => dispatch(fetchDeleteRanch(data.id)), [dispatch]);

    const onCellChange = useCallback(
        ({ data, colDef }) => {
            // eslint-disable-next-line camelcase
            const { id, created_at, ...ranch } = data;
            dispatch(
                fetchUpdateRanchSettings({
                    id,
                    body: {
                        [colDef.field]: ranch[colDef.field],
                    },
                })
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch]
    );

    const gridOptions = useMemo(
        () => ({
            columnDefs: [
                { headerName: 'ID', field: 'id', editable: false },
                { headerName: 'Name', field: 'name', editable: false },
                { headerName: 'Crop', field: 'crop', editable: false },
                {
                    headerName: 'Total area',
                    field: 'totalArea',
                    editable: false,
                },
                {
                    headerName: 'Created At',
                    field: 'createdAt',
                    editable: false,
                },
                { headerName: 'Company Id', field: 'companyId', editable: false },
                {
                    headerName: 'Potential',
                    field: 'showPotential',
                    editable: true,
                    valueGetter: params => params.data?.settings?.showPotential,
                    cellRenderer: 'checkbox',
                    cellRendererParams: {
                        onClick: ({ target: { checked, id } }) => {
                            dispatch(fetchUpdateRanchSettings({ id, body: { showPotential: checked } }));
                        },
                    },
                },
                {
                    headerName: 'CorrectionPercentFactor',
                    field: 'correctionPercentFactor',
                    editable: true,
                    valueGetter: params => params.data?.settings?.correctionPercentFactor?.join(','),
                    valueParser: params => (params.newValue ? params.newValue.split(',')?.map(Number) : null),
                },
                {
                    headerName: 'ExtraFramesFactor',
                    field: 'extraFramesFactor',
                    editable: true,
                    valueGetter: params => params.data?.settings?.extraFramesFactor?.join(','),
                    valueParser: params => (params.newValue ? params.newValue.split(',').map(Number) : null),
                },
            ],
            columnTypes: {
                nonEditableColumn: { editable: false },
            },
        }),
        [dispatch]
    );

    return (
        <div id="view" className="page ranches">
            {isFetched && (
                <Grid
                    components={{
                        checkbox: CheckBox,
                    }}
                    onGridReady={handleOnGridReady}
                    rowData={ranches}
                    gridOptions={gridOptions}
                    handleNewItemSave={handleNewItemSave}
                    handleItemDelete={handleItemDelete}
                    onCellChange={onCellChange}
                />
            )}
        </div>
    );
};

export default loading([
    FETCH_RANCHES.default,
    FETCH_DELETE_RANCH.default,
    FETCH_CREATE_RANCH.default,
    FETCH_UPDATE_RANCH_SETTINGS.default,
])(Ranches);
