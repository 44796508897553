import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@beewise/button';
import { pickBy } from 'lodash-es';
import { useToggle } from '@beewise/react-utils';
import { useForm } from 'react-hook-form';
import { ROOT_ERRORS, getRootLevelErrors } from '@beewise/hook-form';
import { useDispatch } from 'react-redux';
import Modal from '@beewise/modal';
import CreationFields from '../CreationFields';
import { fetchEditSoftwareBundle } from '../../../actions';
import { handleValidate } from '../../BhomeSoftwareBundle';

const EditBundle = ({ bundle }) => {
    const [isEdit, toggleEdit] = useToggle(false);
    const dispatch = useDispatch();
    const form = useForm({
        defaultValues: bundle?.bundle,
    });
    const {
        handleSubmit,
        control,
        formState: { errors },
        setError,
        reset,
    } = form;

    useEffect(() => {
        if (isEdit) {
            toggleEdit();
        }
        reset(bundle?.bundle);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bundle.id]);

    const onSubmit = state => {
        const isValid = handleValidate(state);
        if (!isValid) {
            return setError(ROOT_ERRORS.FORM_ERROR, { type: 'validation', message: 'Please fill all required fields' });
        }
        const nonEmptyFields = pickBy(state, Boolean);
        dispatch(fetchEditSoftwareBundle(bundle.id, nonEmptyFields, toggleEdit));
    };

    const rootErrors = getRootLevelErrors(errors);

    return (
        <div className="edit-bundle">
            <Button onClick={toggleEdit} className="btn-primary">
                Edit Bundle
            </Button>
            {isEdit && (
                <Modal isOpen={isEdit} header="Bundle Edit" className="bundle-edit-modal" onClose={toggleEdit}>
                    <div className="edit-bundle-inputs">
                        <CreationFields control={control} />
                        <div className="error">{rootErrors}</div>
                    </div>
                    <Button onClick={handleSubmit(onSubmit)} className="btn-primary">
                        Save Bundle
                    </Button>
                </Modal>
            )}
        </div>
    );
};

EditBundle.propTypes = {
    bundle: PropTypes.shape(),
};

export default EditBundle;
