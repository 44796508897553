import React, { useState, useCallback, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import TextField from '@beewise/text-field';
import { isBoolean } from 'lodash-es';
import { AgGridReact } from 'ag-grid-react';
import { AllCommunityModule, ModuleRegistry, provideGlobalGridOptions } from 'ag-grid-community';
import AddHeaderComponent from './components/AddHeaderComponent';
import SaveNewItem from './components/SaveNewItem';
import DeleteItem from './components/DeleteItem';
import Link from './components/Cells/Link';

import 'ag-grid-community/styles/ag-grid.min.css';
import 'ag-grid-community/styles/ag-theme-material.min.css';

// Register all community features
ModuleRegistry.registerModules([AllCommunityModule]);
// Mark all grids as using legacy themes
provideGlobalGridOptions({ theme: 'legacy' });

const NEW_ROW_ID = 'NOT_EDITABLE_FIELD';

const Grid = ({
    rowData,
    onGridReady,
    children,
    gridOptions,
    defaultAddItem,
    handleNewItemSave,
    onCellChange,
    handleItemDelete,
    components = {},
    noLeftColumn = false,
    disableCreate = false,
    isSearch = true,
    enableDelete = false,
    ...rest
}) => {
    const [gridApi, setGridApi] = useState(null);
    const [isAddMode, setAddMode] = useState(false);

    const useDynamicCallback = callback => {
        const ref = useRef();
        ref.current = callback;
        return useCallback((...args) => ref.current.apply(this, args), []);
    };

    const onGridReadyHandler = useCallback(
        params => {
            setGridApi(params.api);
            onGridReady(params.api);
        },
        [onGridReady]
    );

    const addNewRow = useDynamicCallback(() => {
        if (gridApi && !isAddMode) {
            setAddMode(true);
            gridApi.applyTransaction({
                add: [{ ...defaultAddItem, addMode: true, id: NEW_ROW_ID }],
                addIndex: 0,
            });

            gridApi.ensureIndexVisible(0, 'top');
        } else if (gridApi && isAddMode) {
            setAddMode(false);
            gridApi.applyTransaction({
                remove: [gridApi.getRowNode(NEW_ROW_ID)],
            });
        }
    });

    const handleItemSave = useDynamicCallback(({ data }) => () => {
        const { id, addMode, ...newItem } = data;
        handleNewItemSave(newItem);
        setAddMode(false);
    });

    const handleGridItemDelete = useDynamicCallback(({ data }) => () => {
        handleItemDelete(data);
    });

    const columnDefs = useMemo(
        () => [
            ...(noLeftColumn
                ? []
                : [
                      {
                          headerName: '',
                          field: 'addMode',
                          editable: false,
                          ...(disableCreate ? {} : { headerComponent: AddHeaderComponent }),
                          headerComponentParams: {
                              addNewRow,
                              gridApi,
                          },
                          width: 110,
                          flex: 0,
                          cellRenderer: 'saveOrDelete',
                          cellClass: 'add-mode-cell',
                      },
                  ]),
            ...gridOptions.columnDefs.map(item => ({
                ...item,
                cellClass: `${item.cellClass ? item.cellClass : ''} ${
                    isBoolean(item.editable) && item.editable ? 'editable-cell' : 'non-editable-cell'
                }`,
            })),
        ],
        [noLeftColumn, disableCreate, addNewRow, gridApi, gridOptions.columnDefs]
    );

    const handleCellChange = useCallback(
        params => {
            if (!params.data.addMode && onCellChange) {
                onCellChange(params);
            }
        },
        [onCellChange]
    );

    const saveOrDeleteHandler = useCallback(
        params =>
            params.data.addMode ? (
                <SaveNewItem handleSave={handleItemSave(params)} {...params} />
            ) : (
                <DeleteItem handleDelete={handleGridItemDelete(params)} enableDelete={enableDelete} {...params} />
            ),
        [enableDelete, handleGridItemDelete, handleItemSave]
    );

    const memoizedComponents = useMemo(
        () => ({
            ...components,
            saveOrDelete: saveOrDeleteHandler,
            link: Link,
        }),
        [components, saveOrDeleteHandler]
    );

    const memoizedGridOptions = useMemo(
        () => ({
            ...gridOptions,
            columnDefs,
            defaultColDef: {
                flex: 1,
                editable: true,
                sortable: true,
                resizable: true,
                filter: 'agTextColumnFilter',
                onCellValueChanged: handleCellChange,
                ...gridOptions.defaultColDef,
            },
            enableCellTextSelection: true,
        }),
        [gridOptions, columnDefs, handleCellChange]
    );

    const handleSearchChange = useCallback(
        value => {
            gridApi?.setGridOption('quickFilterText', value);
        },
        [gridApi]
    );

    return (
        <div className="grid-wrapper">
            {isSearch && (
                <div className="grid-search">
                    <TextField placeholder="Search" onChange={handleSearchChange} size="small" />
                </div>
            )}
            <div
                className={cx('ag-theme-material grid', {
                    'grid-add-mode': isAddMode,
                })}
            >
                <AgGridReact
                    components={memoizedComponents}
                    rowData={rowData}
                    onGridReady={onGridReadyHandler}
                    gridOptions={memoizedGridOptions}
                    getRowId={params => `${params?.data?.id}` || JSON.stringify(params?.data)}
                    {...rest}
                >
                    {children}
                </AgGridReact>
            </div>
        </div>
    );
};

Grid.propTypes = {
    rowData: PropTypes.arrayOf(PropTypes.shape()),
    gridOptions: PropTypes.shape(),
    defaultAddItem: PropTypes.shape(),
    components: PropTypes.shape(),
    onGridReady: PropTypes.func,
    handleNewItemSave: PropTypes.func,
    onCellChange: PropTypes.func,
    handleItemDelete: PropTypes.func,
    children: PropTypes.node,
    noLeftColumn: PropTypes.bool,
    disableCreate: PropTypes.bool,
    isSearch: PropTypes.bool,
    enableDelete: PropTypes.bool,
};

export default Grid;
