import {
    CLEAR_CREATED_BHOME,
    FETCH_ALL_BHOMES,
    FETCH_CREATE_NEW_BHOME,
    FETCH_SIGNUP,
    GET_ALL_AVAILABLE_BHOMES,
    GET_ALL_COMPANIES,
    UPDATE_ADMIN_COMPANY,
    UPDATE_ADMIN_COMPANY_BY_BHOME_ID,
    FETCH_REASSIGN_BHOME,
    GET_USER_ROLES,
    FETCH_CLEAR_USERS_STORAGE,
    FETCH_UPDATE_BHOME_SOFTWARE,
    GET_COMPANY_TYPES,
    FETCH_BULK_UPDATE_SETTINGS,
    FETCH_BHOME_SETTINGS,
    FETCH_BHOME_SETTINGS_HISTORY,
    FETCH_SAVE_BHOME_SETTINGS,
    SET_CURRENT_BHOME,
    FETCH_CREATE_SOFTWARE_BUNDLE,
    FETCH_SOFTWARE_BUNDLES,
    FETCH_DELETE_BHOME_BUNDLE,
    FETCH_CREATE_BHOME_LIGHT,
    FETCH_DELETE_COMPANIES_AND_CONTENT,
    FETCH_DELETE_BHOMES_AND_CLOUD_DATA,
    FETCH_BHOME_CONNECTIVITY_MAP,
    FETCH_POPULATED_HIVES_CSV_REPORT,
    FETCH_BHOME_CLOUD_CONFIG,
    FETCH_SAVE_BHOME_CLOUD_CONFIG,
    FETCH_BULK_UPDATE_CLOUD_CONFIGS,
    FETCH_UPDATE_SOFTWARE_BUNDLE,
} from '../actionTypes';

export const fetchAllCompanies = () => ({
    type: 'FETCH',
    params: {
        type: GET_ALL_COMPANIES.default,
        url: '/companies',
    },
});

export const fetchClearUsersStorage = () => ({
    type: 'FETCH',
    params: {
        type: FETCH_CLEAR_USERS_STORAGE.default,
        method: 'POST',
        url: '/users/clear-users-storage',
        toastText: 'Success!',
        toastOptions: {
            position: 'top-center',
            className: 'beewise-toast action-toast toast-success',
        },
    },
});

export const fetchUpdateBhomeSoftware = (body, resolver) => ({
    type: 'FETCH',
    params: {
        type: FETCH_UPDATE_BHOME_SOFTWARE.default,
        method: 'PUT',
        url: '/bhomes/update-software',
        body,
        toastText: 'Success!',
        toastOptions: {
            position: 'top-center',
            className: 'beewise-toast action-toast toast-success',
        },
        resolver,
    },
});

export const fetchDeleteBhomeBundle = (id, resolver) => ({
    type: 'FETCH',
    params: {
        type: FETCH_DELETE_BHOME_BUNDLE.default,
        method: 'DELETE',
        url: `/bhomes/software-bundle/${id}`,
        toastText: 'Success!',
        toastOptions: {
            position: 'top-center',
            className: 'beewise-toast action-toast toast-success',
        },
        resolver,
    },
});

export const fetchDeleteCompaniesAndContent = (ids, resolver) => ({
    type: 'FETCH',
    params: {
        type: FETCH_DELETE_COMPANIES_AND_CONTENT.default,
        method: 'DELETE',
        body: {
            ids,
        },
        url: '/companies/companies-and-content',
        toastText: 'Success!',
        toastOptions: {
            position: 'top-center',
            className: 'beewise-toast action-toast toast-success',
        },
        resolver,
    },
});

export const fetchDeleteBhomesAndCloudData = (ids, resolver) => ({
    type: 'FETCH',
    params: {
        type: FETCH_DELETE_BHOMES_AND_CLOUD_DATA.default,
        method: 'DELETE',
        body: {
            ids,
        },
        url: '/bhomes/delete-cloud-data',
        toastText: 'Success!',
        toastOptions: {
            position: 'top-center',
            className: 'beewise-toast action-toast toast-success',
        },
        resolver,
    },
});

export const fetchCreateSoftwareBundle = (body, resolver) => ({
    type: 'FETCH',
    params: {
        type: FETCH_CREATE_SOFTWARE_BUNDLE.default,
        method: 'POST',
        url: '/bhomes/software-bundle',
        body,
        toastText: 'Success!',
        toastOptions: {
            position: 'top-center',
            className: 'beewise-toast action-toast toast-success',
        },
        resolver,
    },
});

export const fetchEditSoftwareBundle = (id, body, resolver) => ({
    type: 'FETCH',
    params: {
        type: FETCH_UPDATE_SOFTWARE_BUNDLE.default,
        method: 'PUT',
        url: `/bhomes/software-bundle/${id}`,
        body,
        toastText: 'Success!',
        toastOptions: {
            position: 'top-center',
            className: 'beewise-toast action-toast toast-success',
        },
        resolver,
    },
});

export const fetchSoftwareBundles = () => ({
    type: 'FETCH',
    params: {
        type: FETCH_SOFTWARE_BUNDLES.default,
        url: '/bhomes/software-bundles',
    },
});

export const fetchConnectivityMap = () => ({
    type: 'FETCH',
    params: {
        type: FETCH_BHOME_CONNECTIVITY_MAP.default,
        url: '/bhomes/connectivity-map',
    },
});

export const fetchUserRoles = () => ({
    type: 'FETCH',
    params: {
        type: GET_USER_ROLES.default,
        url: '/users/roles',
    },
});

export const fetchCompanyTypes = () => ({
    type: 'FETCH',
    params: {
        type: GET_COMPANY_TYPES.default,
        url: '/companies/types',
    },
});

export const fetchAllAvailableBhomes = () => ({
    type: 'FETCH',
    params: {
        type: GET_ALL_AVAILABLE_BHOMES.default,
        url: '/bhomes/ids',
    },
});

export const fetchAllBhomes = (idOnly = false) => ({
    type: 'FETCH',
    params: {
        type: FETCH_ALL_BHOMES.default,
        url: `/bhomes/get-all${idOnly ? '?idOnly=true' : ''}`,
    },
});

export const fetchAdminCompanyUpdate = (companyId, resolver) => ({
    type: 'FETCH',
    params: {
        type: UPDATE_ADMIN_COMPANY.default,
        method: 'POST',
        url: '/users/admin',
        body: {
            companyId,
        },
        resolver,
    },
});

export const fetchAdminCompanyUpdateByBhomeId = (bhomeId, resolver) => ({
    type: 'FETCH',
    params: {
        type: UPDATE_ADMIN_COMPANY_BY_BHOME_ID.default,
        method: 'POST',
        url: '/users/admin/bhome',
        body: {
            bhomeId,
        },
        resolver,
    },
});

export const fetchCreateNewBhome = (slotsAmount = 480, id, isBhomeLight) => ({
    type: 'FETCH',
    params: {
        type: FETCH_CREATE_NEW_BHOME.default,
        method: 'POST',
        url: '/bhomes/create',
        body: {
            slotsAmount,
            id,
            isBhomeLight,
        },
    },
});

export const fetchCreateBhomeLight = (slotsAmount, id) => ({
    type: 'FETCH',
    params: {
        type: FETCH_CREATE_BHOME_LIGHT.default,
        method: 'POST',
        url: '/bhomes/iot',
        body: {
            slotsAmount,
            id,
            isBhomeLight: true,
        },
    },
});

export const clearCreatedBhome = () => ({
    type: CLEAR_CREATED_BHOME,
});

export const fetchSignUp = (body, resolver) => ({
    type: 'FETCH',
    params: {
        type: FETCH_SIGNUP.default,
        method: 'POST',
        url: '/users/sign-up',
        body,
        resolver,
    },
});

export const fetchReassignBhome = (body, options) => ({
    type: 'FETCH',
    params: {
        type: FETCH_REASSIGN_BHOME.default,
        method: 'PUT',
        url: '/bhomes/change-company',
        body,
        ...options,
    },
});

export const fetchBulkUpdateSettings = (bhomes, settings, key, shouldOverride, enforceImmediateAction, options) => ({
    type: 'FETCH',
    params: {
        type: FETCH_BULK_UPDATE_SETTINGS.default,
        method: 'PUT',
        url: '/bhomes/bulk-update-settings',
        body: {
            bhomes,
            settings,
            key,
            shouldOverride,
            enforceImmediateAction,
        },
        ...options,
    },
});

export const fetchBulkUpdateCloudConfigs = (bhomes, configs, key, shouldOverride, options) => ({
    type: 'FETCH',
    params: {
        type: FETCH_BULK_UPDATE_CLOUD_CONFIGS.default,
        method: 'PUT',
        url: '/bhomes/bulk-update-cloud-configs',
        body: {
            bhomes,
            configs,
            key,
            shouldOverride,
        },
        ...options,
    },
});

export const fetchBhomeSettings = (id, resolver) => ({
    type: 'FETCH',
    params: {
        type: FETCH_BHOME_SETTINGS.default,
        url: `/bhomes/${id}/settings`,
        resolver,
    },
});

export const fetchBhomeCloudConfig = (id, resolver) => ({
    type: 'FETCH',
    params: {
        type: FETCH_BHOME_CLOUD_CONFIG.default,
        url: `/bhomes/${id}/config`,
        resolver,
    },
});

export const fetchHivesCsvReportByType = (type, resolver) => ({
    type: 'FETCH',
    params: {
        type: FETCH_POPULATED_HIVES_CSV_REPORT.default,
        url: `/bhomes/v4/reports/${type}`,
        resolver,
        failureToastText: 'Error on CSV report creation',
        toastText:
            "CSV report is preparing, please don't close the tab until download started (can take up to 15 minutes)",
    },
});

export const fetchBhomeSettingsHistory = (id, resolver) => ({
    type: 'FETCH',
    params: {
        type: FETCH_BHOME_SETTINGS_HISTORY.default,
        url: `/bhomes/${id}/settings-history`,
        resolver,
    },
});

export const fetchSaveSettings = ({
    id,
    settings,
    previousSettingsData,
    disableHashCheck = false,
    enforceImmediateAction = false,
    resolver,
}) => ({
    type: 'FETCH',
    params: {
        type: FETCH_SAVE_BHOME_SETTINGS.default,
        method: 'PUT',
        url: `/bhomes/${id}/settings`,
        body: {
            updateBody: settings,
            previousSettingsData,
            disableHashCheck,
            enforceImmediateAction,
        },
        toastText: 'Command sent',
        toastOptions: {
            toastType: 'toast-success',
            position: 'bottom-center',
            buttonText: null,
        },
        resolver,
    },
});

export const fetchSaveConfig = ({ id, updateBody, resolver }) => ({
    type: 'FETCH',
    params: {
        type: FETCH_SAVE_BHOME_CLOUD_CONFIG.default,
        method: 'PUT',
        url: `/bhomes/${id}/config`,
        body: {
            updateBody,
        },
        toastText: 'Command sent',
        toastOptions: {
            toastType: 'toast-success',
            position: 'bottom-center',
            buttonText: null,
        },
        resolver,
    },
});

export const setCurrentBhome = bhomeId => ({
    type: SET_CURRENT_BHOME,
    bhomeId,
});
